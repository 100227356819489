import React from "react";
import './AllTheCSS/Footer.css'
import FaceBook from "./Pictures/FaceBook";
import Instagram from "./Pictures/Instagram";
import YouTube from "./Pictures/YoutTube";
import {redirectToExternalSite} from "./Helpers";
import paul from "./Pictures/ContactUsBackground.webp";
import aboutContactUs from './Pictures/GarrisonAbout.webp'
import homeContactUs from './Pictures/HomeBottomBackground.webp'
import CustomButton from "./CustomButton";

interface props {
    pageSelector: number
    setPageSelector: React.Dispatch<React.SetStateAction<number>>
    handleClick: (value: number, name: string) => void
}

const Footer = ({pageSelector,handleClick} : props) => {

    const backgroundSelector = () => {

        switch (pageSelector) {
            case 0:
                return homeContactUs
            case 1:
                return aboutContactUs
            case 2:
                return paul
            default: return
        }
    }

    return (
        <>
        {pageSelector < 8 &&
        <>
            {pageSelector < 3 &&
            <div className={'ContactUs-Container'} style={{backgroundImage:`url(${backgroundSelector()})`, backgroundSize: "cover", backgroundPosition:pageSelector == 0 ? '50%' : pageSelector === 1 ? '26%' : '70%'}}>
                <div className={'ContactUs-Content'}>
                    <div className={'ContactUs-Text'}>
                        CONTACT US
                    </div>
                    <div className={'ContactUs-Button-Container'}>
                        <CustomButton onClick={() => handleClick(3, 'Recruiter')}>APPLY NOW</CustomButton>
                        <CustomButton onClick={() => handleClick(3, 'question')} >REQUEST INFO</CustomButton>
                    </div>
                </div>
                <div style={{textAlign:'left', width:'80%', color:'#999999', textDecoration:'underline', height:'4rem'}}>
                    <div style={{ cursor:"pointer", width:'6rem'}} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} >Back To Top</div>
                </div>
            </div>
            }

            <div className={'Footer-Container'}>
                <div className={'Footer-Container-Text'}>
                    <div >
                        <p onClick={() => handleClick(0,'')} style={{cursor:'pointer'}}>HOME</p>
                    </div>
                    <div>
                        <p>ABOUT</p>
                        <p onClick={() => handleClick(1,'mission')} style={{fontSize:'small', cursor:'pointer'}}>Who we are</p>
                        <p onClick={() => handleClick(1, 'LifeInReg')} style={{fontSize:'small', cursor:'pointer'}}>Life in Regiment</p>
                    </div>
                    <div>
                        <p >CAREER PATH</p>
                        <p onClick={() => handleClick(2,'whichPath')} style={{fontSize:'small', cursor:'pointer'}}>Becoming a Ranger</p>
                        <p onClick={() => handleClick(2,'officer')} style={{fontSize:'small', cursor:'pointer'}}>Ways to Serve</p>
                    </div>
                    <div>
                        <p>CONNECT</p>
                        <p onClick={() => handleClick(3,'recruiter')} style={{fontSize:'small', cursor:'pointer'}}>Contact a Recruiter</p>
                        <p onClick={() => handleClick(3,'question')} style={{fontSize:'small', cursor:'pointer'}}>Ask a Question</p>
                    </div>
                    <div>
                        <p onClick={() => handleClick(4,'')} style={{cursor:'pointer'}}>FAQ</p>
                    </div>
                    <div style={{fontSize:'small'}}>
                        <p>RECRUITING HOTLINE: (706)545-5124</p>
                        <p>ENLISTED: 75RECRUIT@SOCOM.MIL</p>
                        <p>OFFICER/WARRANT: 75OFFICERRECRUIT@SOCOM.MIL</p>
                    </div>
                </div>

                <div className={'SocialMedia'}>
                    <div style={{width:'30%', marginBottom:'.5rem', fontSize:'12px', color:'#999999'}}>
                        <a style={{cursor:'pointer', textDecoration:'underline'}} onClick={() => redirectToExternalSite("FOIA")}>FOIA</a> |
                        <a style={{cursor:'pointer', textDecoration:'underline'}} onClick={() => redirectToExternalSite("PP")}> Privacy Policy</a> |
                        <a style={{cursor:'pointer', textDecoration:'underline'}} onClick={() => redirectToExternalSite("SOF")}> SOF Recruiting</a>
                    </div>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:"space-around", width:'10rem', height:'100%'}}>
                        <div style={{cursor:'pointer'}} onClick={() => redirectToExternalSite("F")}>
                            {FaceBook('white', '', '')}
                        </div>
                        <div style={{cursor:'pointer'}} onClick={() => redirectToExternalSite("I")}>
                            {Instagram('white', '', '')}
                        </div>
                        <div style={{cursor:'pointer'}} onClick={() => redirectToExternalSite("Y")}>
                            {YouTube('white', '', '')}
                        </div>
                    </div>
                    <div style={{width:'30%', textAlign:'end', fontSize:'12px'}}>© 2024 75th Ranger Regiment</div>
                </div>

            </div>
        </>
        }
        </>
    )
}
export default Footer;