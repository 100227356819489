import React from "react"
import "./AllTheCSS/EventMap.css"
import EventMapPic from "./Pictures/EventMap.webp"
import MapPin from "./Pictures/MapPin.webp"
import Reveal from "./Reveal";

// interface props {
//     setPageSelector: React.Dispatch<React.SetStateAction<number>>
// }
const EventMap = () => {


    return(
        <div className={'EventMap-Container'}>
            <Reveal>
                <div className={'EventMap-Content'}>
                    <img className={'EventMap-Pic'} src={EventMapPic} alt={"EventMap"}/>
                    <div className={'EventMap-Locations-Container'}>
                        <div className={'EventMap-Location-Pin'}>
                            <img className={'EventMap-MapPin'} src={MapPin} alt={"Vector"}/>
                            <a href={"https://maps.app.goo.gl/zRyAuujoreahNZ5w9"} target={"_blank"} className={'EventMap-MapPin-Text'}> BATTALION QUAD </a>
                        </div>
                        <div className={'EventMap-Location-Pin'}>
                            <img className={'EventMap-MapPin'} src={MapPin} alt={"Vector"}/>
                            <a href={"https://maps.app.goo.gl/kd6L72VnymK1qQef8"} target={"_blank"} className={'EventMap-MapPin-Text'}> RANGER DFAC </a>
                        </div>
                        <div className={'EventMap-Location-Pin'}>
                            <img className={'EventMap-MapPin'} src={MapPin} alt={"Vector"}/>
                            <a href={"https://maps.app.goo.gl/MR4s6v6ra9h5z9dc8"} target={"_blank"} className={'EventMap-MapPin-Text'}> HPTC </a>
                        </div>
                        <div className={'EventMap-Location-Pin'}>
                            <img className={'EventMap-MapPin'} src={MapPin} alt={"Vector"}/>
                            <a href={"https://maps.app.goo.gl/bRXEyutDGf8kojz87"} target={"_blank"} className={'EventMap-MapPin-Text'}> K9 KENNELS </a>
                        </div>
                        <div className={'EventMap-Location-Pin'}>
                            <img className={'EventMap-MapPin'} src={MapPin} alt={"Vector"}/>
                            <a href={"https://maps.app.goo.gl/fdj7KZUBy6KZHKHQ7"} target={"_blank"} className={'EventMap-MapPin-Text'}> FRIES TOWER </a>
                        </div>
                        <div className={'EventMap-Location-Pin'}>
                            <img className={'EventMap-MapPin'} src={MapPin} alt={"Vector"}/>
                            <a href={"https://maps.app.goo.gl/QYGxF3AckrSMbTUt8"} target={"_blank"} className={'EventMap-MapPin-Text'}> NOBLE HILL </a>
                        </div>
                        <div className={'EventMap-Location-Pin'}>
                            <img className={'EventMap-MapPin'} src={MapPin} alt={"Vector"}/>
                            <a href={"https://maps.app.goo.gl/vWXu8f2Ru5tZARSi7"} target={"_blank"} className={'EventMap-MapPin-Text'}> RANGE 7 </a>
                        </div>
                    </div>
                </div>
            </Reveal>

        </div>
    )
}

export default EventMap