import React, {useEffect, useState} from "react"
import EventTimeline from "./Pictures/TimelinePoster.webp"
import EventTimelineMobile from "./Pictures/TimeLineMobile.webp"
import WashDUI from './Pictures/WashingtonDUI.webp'
import GolfFlier from './Pictures/GolfFlier.webp'
import GolfBall from "./Pictures/Golf.webp"
import ARSOA from './Pictures/ARSOARide.webp'
import ACoQR from './Pictures/ACOQR.png'
import Regiment from './Pictures/RegimentSticker.webp'
import CreatedBy from './Pictures/CreatedBy.webp'
import TmRainier from './Pictures/TmRainierLogo.webp'
import "./AllTheCSS/Timeline.css"
import Reveal from "./Reveal";

const Timeline = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(false)

    const handleClick = (num:number) => {
        switch(num) {
            case 1:
                window.open("https://maps.app.goo.gl/H3QLKkDMyU23p8c26")
                break
            case 2:
                window.open( "https://maps.app.goo.gl/RrLJLEydaoY37joc9")
                break
            case 3:
                window.open( "https://maps.app.goo.gl/JEqepHWaMi8wFMW57")
                break
            case 4:
                window.open( "https://maps.app.goo.gl/7eefecE9UDCwohRj6")
                break
            case 5:
                window.open( "https://maps.app.goo.gl/VSVGyipCu8zTcTjv7")
                break
            case 6:
                window.open( "https://maps.app.goo.gl/wtUu2HzFQ6rPf8Zp8")
                break
            case 7:
                window.open( "https://maps.app.goo.gl/arSNx8UK5SeaTBTs8")
                break
            case 8:
                window.open( "https://maps.app.goo.gl/gRY27DzmwNCzyBYR8")
                break
            case 9:
                window.open( "https://maps.app.goo.gl/PpCG5MgjVStHXhYR6")
                break
            case 10:
                window.open( "https://maps.app.goo.gl/xdSA4aQ82yeuaf716")
                break
            case 11:
                window.open( "https://maps.app.goo.gl/ws4vGvgJv6kcpPx8A")
                break
            case 12:
                window.open("https://docs.google.com/forms/d/e/1FAIpQLSdc3abP8Xf8d-4_eAA7Kqb_-f2Hdc0Jf0NLMVqOSXGXUWYsbw/viewform")
                break
            case 13:
                window.open("https://www.mcmenamins.com/elks-temple/mcmenamins-pub-at-elks-temple")
                break
            case 14:
                window.open("https://www.7seasbrewing.com/")
                break

            case 15:
                window.open("https://www.dystopianstate.com/?utm_source=google&utm_medium=wix_google_business_profile&utm_campaign=13153364931624358029")
                break
            case 16:
                window.open("https://narrowsbrewing.com/")
                break



        }
    }


    useEffect(() => {
        setIsSmallScreen(window.innerWidth <= 900)
        window.scrollTo({top: 0, behavior: 'auto'});
        const handleResize = () => {
            const newWindowWidth = window.innerWidth;
            if (newWindowWidth <= 900) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false)
            }
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    },[])


    return(
                <div className={'Timeline-Container'}>
                    <img className={'Wash-Dui'}src={WashDUI}/>
                    <img className={'Regiment-Background-Pic'} src={Regiment}/>
                    <div className={"QRCode-container"}>
                        <Reveal>
                            <>
                                <img className={'Timeline-Pic'} src={isSmallScreen ? EventTimelineMobile : EventTimeline} alt={'TIMELINE'}/>
                                {/*<div className={"Timeline-Golf-RSVP"} onClick={() => handleClick(12)}><img style={{height:"95%", marginRight:"5%"}} src={GolfBall} alt={"GolfBall"}/>RSVP</div>*/}
                                <div onClick={() => handleClick(1)} className={"QRoverlay QR1"}/>
                                <div onClick={() => handleClick(2)} className={"QRoverlay QR2"}/>
                                <div onClick={() => handleClick(3)} className={"QRoverlay QR3"}/>
                                <div onClick={() => handleClick(4)} className={"QRoverlay QR4"}/>
                                <div onClick={() => handleClick(5)} className={"QRoverlay QR5"}/>
                                <div onClick={() => handleClick(6)} className={"QRoverlay2 QR6"}/>
                                <div onClick={() => handleClick(7)} className={"QRoverlay2 QR7"}/>
                                <div onClick={() => handleClick(8)} className={"QRoverlay2 QR8"}/>
                                <div onClick={() => handleClick(9)} className={"QRoverlay2 QR9"}/>
                                <div onClick={() => handleClick(10)} className={"QRoverlay2 QR10"}/>
                                <div onClick={() => handleClick(11)} className={"QRoverlay2 QR11"}/>
                                </>
                        </Reveal>
                    </div>
                    <div className={'Golf-Event-Container'}>
                        <section className={'Left-Side-Pic'}>
                            <img src={GolfFlier}/>
                        </section>
                        <section className={'Right-Side-Content'}>
                            <p> Thursday 03 OCT</p>
                            <p style={{fontSize:'12px', lineHeight:'16px', marginBlockStart:'0', color:'#1C222E'}}> 0800-UTC </p>
                            <h2> GOLF TOURNAMENT</h2>
                            <ul style={{marginBottom:'2rem'}} className={'Timeline-List-Styling'}>
                                <li>Check in opens at 0800 - Shotgun start at 0900 </li>
                                <li>Eagle's Pride Golf Course: 1529 Mounts Road SW, DuPont, WA 98327 </li>
                                <li>All greens fees and food costs are courtesy of the Point du Hoc Foundation, alcohol is available for purchase </li>
                                <li>Luncheon to follow </li>
                                <li>Spots are expected to fill up quick. RSVP now! </li>
                            </ul>
                            <p>**Each foursome will consist of one current Ranger and 3 Alumni** </p>
                            <div className={'Timeline-Button-Container'}>
                                <div className={"Timeline-Golf-RSVP"} onClick={() => handleClick(12)}><img style={{height:"30%", marginRight:"2%"}} src={GolfBall} alt={"GolfBall"}/>RSVP</div>
                            </div>
                            <p className={'Contact-text'}>Contact us at jmcerlean2012@gmail.com for any additional questions</p>
                        </section>
                    </div>
                    <div className={'Company-Event-Container'} style={{marginBottom:'8rem'}}>
                        <section className={'Left-Side-Company'}>
                            <p> Thursday 03 OCT</p>
                            <p style={{fontSize:'12px', lineHeight:'16px', marginBlockStart:'0', color:'#1C222E'}}> 1300-UTC </p>
                            <h2> COMPANY HOSTED SOCIALS </h2>
                            <div className={'Company-QR-Container'}>
                                <div className={'Company-QR-Block'}>
                                    <h3>A CO</h3>
                                    <img src={ACoQR}/>
                                    <h4 onClick={()=> handleClick(13)}>McMenamins Elk Temple </h4>
                                </div>
                                <div className={'Company-QR-Block'}>
                                    <h3>B CO </h3>
                                    <img src={ACoQR}/>
                                    <h4 onClick={()=> handleClick(14)}>7 Seas Brewing Company </h4>
                                </div>
                                <div className={'Company-QR-Block'}>
                                    <h3>C CO </h3>
                                    <img src={ACoQR}/>
                                    <h4 onClick={()=> handleClick(15)}>Dystopian State Brewing</h4>
                                </div>
                                <div className={'Company-QR-Block'}>
                                    <h3>D Co, E Co & HHC </h3>
                                    <img src={ACoQR}/>
                                    <h4 onClick={()=> handleClick(16)}>Narrows Brewing</h4>
                                </div>

                            </div>
                        </section>
                        <section className={'Right-Side-Company'}>
                            <img style={{width:'43rem'}} src={ARSOA}/>
                        </section>
                    </div>
                    <img src={CreatedBy} style={{position:'absolute',width:'4rem', top:'97%'}}/>
                    <img src={TmRainier} style={{position:'absolute',width:'4rem', top:'98%'}}/>
                </div>
    )
}

export default Timeline