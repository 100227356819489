import React, { useEffect, useRef, useState } from "react";
import faqPic from "./Pictures/FAQpic.webp"
import './AllTheCSS/FAQ.css'
import ExpandIcon from "./Pictures/ExpandIcon";
import CustomButton from "./CustomButton";
import {AnimatePresence, motion} from "framer-motion";
import {redirectToExternalSite} from "./Helpers"



interface Props {
    name: string;
    setPageSelector: React.Dispatch<React.SetStateAction<number>>
    setName: React.Dispatch<React.SetStateAction<string>>
}

const FAQ: React.FC<Props> = ({name, setPageSelector, setName }: Props) => {
    const [showDiv, setShowDiv] = useState([false, false, false, false, false, false, false])
    const [isClicked, setIsClicked] = useState([false, false, false, false, false, false, false])
    const [smallScreen, setSmallScreen] = useState(false)

    useEffect(() => {
        setSmallScreen(window.innerWidth < 950)

        window.scrollTo({top: 0, behavior: 'auto'});

        const handleResize = () => {
            const newWindowWidth = window.innerWidth;
            if (newWindowWidth < 950) {
                setSmallScreen(true);
            } else {
                setSmallScreen(false)
            }
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    },[])



    useEffect(()=>{
        setIsClicked([false,false,false,false,false,false,false])
    },[showDiv])


    const handleClick = (num: number, idx: number | null = null) => {
        const updatedShowDiv = showDiv.map((val, index) => index === num ? !val : false);

        console.log("updated showdiv:",updatedShowDiv);
        setShowDiv(updatedShowDiv);
        console.log("newshowdiv",showDiv)

    }

    const handleIsClicked = (num: number, idx: number | null = null) => {
        const upDateIsClicked = isClicked.map((val: boolean, index) => index === num ? !val: val)
        setIsClicked(upDateIsClicked)
    }


    const options = (num: number) => {
        let divOptions = null
        switch (num) {
            case 0:
                divOptions = (
                    <motion.div
                        className={'Eligibility-Options'}
                        key={'a'}
                        initial={{ height: 0 }}
                        animate={{ height: 'auto' }}
                        exit={{ height: 0 }}
                        transition={{  duration: smallScreen ? .2 : 0}}>
                        <div className={'Question-Container'} >
                            <div className={'question-Paragraph'} onClick={()=>{handleIsClicked(0)}} >My Recruiter says he/she cannot give me an Option 40 contract. What should I do?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[0]}/></div>
                            </div>
                            <AnimatePresence initial={false}>
                                {isClicked[0] && (
                                    <motion.p
                                        key={0}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                        Contact us at 75recruit@socom.mil or call us at 706-545-5124.
                                    </motion.p>
                                ) }
                            </AnimatePresence>

                        </div>
                        <div className={'Question-Container'} >
                            <p className={'question-Paragraph'} onClick={()=>{handleIsClicked(1)}}>I did not get an option 40 contract when I enlisted, but I still want to join the Ranger Regiment. What should I do?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[1]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[1] && (
                                    <motion.p
                                        key={1}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                        <p style={{marginBottom:'.5rem'}}> Contact your Ranger Recruiter Liaison stationed at your AIT/OSUT and ask them for a volunteer statement. If successful at RASP 1, you may be entitled to the same option 40 bonus available at the time of your enlistment. We have LNOs at the following AIT/OSUT stations:</p>
                                        <ul className={'faq-Ul'}>
                                            <li>Ft Moore, GA (OSUT / Airborne School)</li>
                                            <li>Ft Huachuca, AZ</li>
                                            <li>Ft Sill, OK</li>
                                            <li>Joint Base San Antonio – Fort Sam Huston, TX</li>
                                            <li>Ft Eisenhower, GA</li>
                                            <li>Ft Jackson, SC</li>
                                            <li>Ft Gregg-Adams, VA</li>
                                            <li>Ft Leonard Wood, MO</li>
                                        </ul>
                                        <p>If there is not a Ranger Recruiting LNO at your AIT/OSUT, contact us at 706-545-5124 or email us at 75recruit@socom.mil.</p>
                                        <p>Assuming  you meet all the entry requirements AND you sign a volunteer statement, you will have an opportunity to attend RASP 1 immediately following AIT/OSUT.</p>
                                    </motion.p>
                                )}
                            </AnimatePresence>

                        </div>
                        <div className={'Question-Container'} >
                            <p className={'question-Paragraph'} onClick={()=>{handleIsClicked(2) }}>I’m currently in AIT, what can I do to prepare for RASP 1?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[2]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[2] && (
                                    <motion.p
                                        key={2}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                        <p>Each Ranger Recruiting Liaison has a Ranger mentorship program at their  AIT location. The Ranger mentorship program includes daily physical  training designed to prepare you for the challenges at RASP 1 along with  professional development specific to your MOS.</p>
                                        <p>If you  missed the Ranger Recruiting brief at AIT or have trouble finding the  Ranger Recruiting Liaison at your location, contact us at 706-545-5124  or email us at 75recruit@socom.mil.</p>
                                    </motion.p>
                                )}
                            </AnimatePresence>

                        </div>
                    </motion.div>
                )
                break;
            case 1:
                divOptions = (
                    <motion.div
                        className={'Eligibility-Options'}
                        key={'b'}
                        initial={{ height: 0 }}
                        animate={{ height: 'auto' }}
                        exit={{ height: 0 }}
                        transition={{ duration: smallScreen ? .2 : 0 }}>
                        <div className={'Question-Container'} >
                           <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(0)}}> I'm not airborne qualified. Can I still apply?
                               <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[0]}/></div>
                           </p>
                            <AnimatePresence initial={false}>
                                {isClicked[0] && (
                                    <motion.p
                                        key={0}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                        Yes. Send a copy of your STP to 75recruit@socom.mil or contact us below. If you successfully pass RASP, you'll be sent directly to Airborne School.
                                    </motion.p>
                                )}
                            </AnimatePresence>

                        </div>
                        <div className={'Question-Container'} >
                            <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(1)}}> Do I need a Ranger tab to apply?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[1]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[1] && (
                                    <motion.p
                                        key={1}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                       <p>Combat arms MOS's (11B , 13F , 68W) in the grade of E4(P) and up must have a Ranger tab to apply.</p>
                                        <p>All officers must have a Ranger Tab to apply.</p>
                                        <p>Exceptions may be considered on a case-by-case basis for low density officer branches.</p>
                                        <p>All other enlisted MOSs and warrant officers do not need a Ranger Tab to apply.</p>
                                    </motion.p>
                                )}
                            </AnimatePresence>
                        </div>
                        <div className={'Question-Container'} >
                            <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(2)}}>
                                Will lack of deployment experience hurt my chances at being hired?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[2]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[2] && (
                                    <motion.p
                                        key={2}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                       No.
                                    </motion.p>
                                )}
                            </AnimatePresence>

                        </div>
                        <div  className={'Question-Container'} >
                            <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(3)}}>Do I need a security clearance to attend RASP?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[3]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[3] && (
                                    <motion.p
                                        key={3}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                       <p>You need at least a Secret security clearance to attend RASP 2 (E5(P) and up).</p>
                                        <p>You do not need a security clearance to attend RASP 1 but must be able to obtain at least a Secret clearance.</p>
                                    </motion.p>
                                )}
                            </AnimatePresence>

                        </div>
                        <div  className={'Question-Container'} >
                            <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(4)}}> My BDE  Commander (or equivalent O6 senior rater) has not gotten me the  4187/PAR and/or the Letter of Recommendation (LOR) back, can I still  submit my application?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[4]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[4] && (
                                    <motion.p
                                        key={4}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                        Yes, you may still submit your  application, but you will still need a signed 4187/PAR before you can attend RASP. The same applies if you have a signed 4187 but are still  waiting on your LOR. Your application is not complete until you submit a signed 4187/PAR and LOR.
                                    </motion.p>
                                )}
                            </AnimatePresence>

                        </div>
                        <div  className={'Question-Container'} >
                            <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(5)}}>Do you accept female applicants?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[5]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[5] && (
                                    <motion.p
                                        key={5}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                        Yes, female applicants are encouraged to apply for any position in the Ranger Regiment.
                                    </motion.p>
                                )}
                            </AnimatePresence>

                        </div>

                    </motion.div>

                )

                break;
            case 2:
                divOptions = (
                    <motion.div
                        className={'Eligibility-Options'}
                        key={'c'}
                        initial={{ height: 0 }}
                        animate={{ height: 'auto' }}
                        exit={{ height: 0 }}
                        transition={{  duration: smallScreen ? .2 : 0 }}>
                            <div className={'Question-Container'} >
                                <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(0)}}>My MOS is not authorized in the Ranger Regiment. Can I still apply?
                                    <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[0]}/></div>
                                </p>
                                <AnimatePresence initial={false}>
                                    {isClicked[0] && (
                                        <motion.p
                                            key={0}
                                            className={'Eligibility-Questions'}
                                            initial={{ height: 0 }}
                                            animate={{ height: "auto" }}
                                            exit={{ height: 0 }}
                                            transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                            Yes, send a copy of your STP to 75recruit@socom.mil. If you are accepted to attend RASP, you'll be re-classed into an authorized MOS after completion of RASP.
                                            The decision on what MOS you re-class into will depend on current strengths in the Regiment.
                                        </motion.p>
                                    )}
                                </AnimatePresence>

                            </div>

                            <div className={'Question-Container'} >
                                <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(1)}}>I do not have a 100GT score. Can I still apply?
                                    <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[1]}/></div>
                                </p>
                                <AnimatePresence initial={false}>
                                    {isClicked[1] && (
                                        <motion.p
                                            key={1}
                                            className={'Eligibility-Questions'}
                                            initial={{ height: 0 }}
                                            animate={{ height: "auto" }}
                                            exit={{ height: 0 }}
                                            transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                            GT scores may be waived on a case-by-case basis. Send a copy of your STP to 75recruit@socom.mil or contact your Ranger Recruiting Liaison at your AIT/OSUT and ask if you're eligible.
                                            In-service applications are encouraged to enroll in the Basic Skills Education Program (BSEP) to increase your GT score. After successful completion of RASP, Soldiers with less than a 100 GT score will be required to raise their score within 12 months of arriving to their Battalion or be subject to Release for Standards (RFS).
                                        </motion.p>
                                    )}
                                </AnimatePresence>

                            </div>

                            <div className={'Question-Container'} >
                                <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(2)}}> How long do I need to be on station to PCS to the Ranger Regiment?
                                    <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[2]}/></div>
                                </p>
                                <AnimatePresence initial={false}>
                                    {isClicked[2] && (
                                        <motion.p
                                            key={2}
                                            className={'Eligibility-Questions'}
                                            initial={{ height: 0 }}
                                            animate={{ height: "auto" }}
                                            exit={{ height: 0 }}
                                            transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                            <p>You should have at least 12 months time on station before you're able to PCS. You may be able to attend RASP before meeting 12 months time on station.</p>
                                            <p>Also, be aware that if you have an RFO for a follow on assignment, you may not be eligible to apply to the 75th Ranger Regiment.</p>
                                        </motion.p>
                                    )}
                                </AnimatePresence>

                             </div>

                    </motion.div>

                )
                break;
            case 3:
                divOptions = (

                    <motion.div
                        className={'Eligibility-Options'}
                        key={'d'}
                        initial={{ height: 0 }}
                        animate={{ height: "auto" }}
                        exit={{ height: 0 }}
                        transition={{ duration: smallScreen ? .2 : 0 }}>
                        <div className={'Question-Container'}>
                            <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(0)}}>My officer branch is not on the list of eligible branches in the Ranger Regiment, am I still able to apply?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[0]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[0] && (
                                    <motion.p
                                        key={0}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                        <p>Yes; officers not on our list of approved branches may apply to the
                                        Ranger Regiment through the HQDA Talent Based Career Alignment (TBCA) Program.
                                        You will submit your application prior to attending the Captains Career Course for potential service in the Ranger Regiment following your Captain key development position. </p>
                                       <p>Follow the link below for additional information about the TBCA program.</p>
                                           <a className={"HyperLink"} onClick={() => redirectToExternalSite("Talent")}>https://talent.army.mil/tbca/A</a>
                                    </motion.p>
                                )}
                            </AnimatePresence>

                        </div>
                        <div className={'Question-Container'} >
                            <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(1)}}>How do I know if I will have enough time in my professional timeline to be hired by the Ranger Regiment?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[1]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[1] && (
                                    <motion.p
                                        key={1}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                        The Ranger Regiment expects our officer to serve for at least 18-24 months.
                                        You are required to depart for your next Professional Military Education—usually CCC or ILE—on the timeline prescribed by your branch manager (usually YG+4 for CCC and YG+10 for ILE).
                                        If you can’t meet the  minimum utilization requirements your application may be rejected.
                                        For guidance specific to your career path, speak with a Ranger Recruiter and  your branch manager.
                                    </motion.p>
                                )}
                            </AnimatePresence>

                        </div>

                        <div className={'Question-Container'} >
                            <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(2)}}>What can I do to make myself more competitive for selection?
                                How and when can I apply?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[2]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[2] && (
                                    <motion.p
                                        key={2}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                        There is no specific formula but here are some steps that may help:
                                        earn a Ranger Tab; pursue leadership opportunities early and often in operational units, and excel at those positions;
                                        attend schools focused  on developing skills relevant to your branch/MOS and of potential use in the Ranger Regiment (examples: RSLC, Airborne, IMLC, Jumpmaster, UMO,  TC-AIMS, SHARP, RESILIENCY, etc.); seek candid feedback from peers,
                                        subordinates and superiors; and continually accept challenges and grow  from every success and failure.
                                    </motion.p>
                                )}
                            </AnimatePresence>

                        </div>

                        <div className={'Question-Container'} >
                            <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(3)}}>I am a Lieutenant that  is branch detailed; should I apply for a position in my current branch  or future branch? Do I need to attend my future branch CCC first?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[3]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[3] && (
                                    <motion.p
                                        key={3}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                        You may apply for positions within your current branch or your future branch.
                                        We will also help determine which role you’ll serve in the Regiment based on our current manning.
                                        We may hire you for a position in your future branch before attending CCC.
                                    </motion.p>
                                )}
                            </AnimatePresence>
                        </div>

                        <div className={'Question-Container'} >
                            <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(4)}}>I saw you  may hire Warrant Officers directly out of WOCS/WOBC. Do you select  officers directly out of BOLC for service in the Regiment?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[4]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[4] && (
                                    <motion.p
                                        key={4}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                        In very rare and specific circumstances, we will identify uniquely talented support/enabling branch officers in their BOLC class for early entry into the Ranger Regiment.
                                        Less than 5 candidates per year are assessed in this manner and most are prior service, Ranger qualified 2LTs that demonstrated distinguishable service as enlisted soldiers.
                                    </motion.p>
                                )}
                            </AnimatePresence>

                        </div>

                    </motion.div>

                )
                break;
            case 4:
                divOptions = (
                    <motion.div
                        className={'Eligibility-Options'}
                        key={'e'}
                        initial={{ height: 0 }}
                        animate={{ height: 'auto' }}
                        exit={{ height: 0 }}
                        transition={{ duration: smallScreen ? .2 : 0 }}>
                        <div className={'Question-Container'} >
                            <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(0)}}> What are the formats for my Letter of Recommendation and/or the Letter of Intent? What should they cover?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[0]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[0] && (
                                    <motion.p
                                        key={0}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                        Reference AR 25-50 for formatting. Content of each letter is up to the  writer.
                                        How the letters are crafted and the message they convey is part of the assessment, the Recruiting Detachment will not provide any additional guidance.
                                    </motion.p>
                                )}
                            </AnimatePresence>
                        </div>
                        <div className={'Question-Container'} >
                            <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(1)}}>What does a strong file for a RASP 2 candidate look like?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[1]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[1] && (
                                    <motion.p
                                        key={1}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                        A record of superior performance as captured in your AERs (Commandant’s List, Exceeded Course standards, Honor Graduate), evaluations (majority “Top Blocks” ACOM / MQ, exclusive enumeration in Senior Rater comments, potential to serve in the next grade), and Letters of Recommendation (strongly worded examples of distinguished performance/potential in relation to your peers, personal interaction with the candidate).
                                    </motion.p>
                                )}
                            </AnimatePresence>

                            </div>
                        <div className={'Question-Container'} >
                            <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(2)}}> How long does the application process take? How soon after RASP will I be expected to PCS to the Ranger Regiment?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[2]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[2] && (
                                    <motion.p
                                        key={2}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                        The application and RASP slotting timeline is variable depending on the current manning and requirements of the Ranger Regiment and quality of the applicant. Once your application is complete, you may be slotted for RASP in as little as one month, or it may take up to eight months. Generally candidates selected at RASP are expected to PCS immediately following RASP or the date specified by your chain of command on your 4187/PAR.
                                    </motion.p>
                                )}
                            </AnimatePresence>

                        </div>
                        <div className={'Question-Container'} >
                            <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(3)}}>I am an NCO selected to attend WOCS & WOBC and I would like to serve in the 75th Ranger Regiment. How and when can I apply?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[3]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[3] && (
                                    <motion.p
                                        key={3}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                        Based on the strength of your file and your previous service in the Career Field (i.e.- 92Y earns an appointment to 920A) select candidates will be invited to attend RASP 2 shortly before or after WOCS/WOBC. If successful, we will work with your Assignment Officer to make the Ranger Regiment your next assignment. Other interested NCOs heading to WOCS/WOBC whose file or timing doesn’t work out can apply IAW our recruiting guidance while serving in a unit following WOCS/WOBC.
                                    </motion.p>
                                )}
                            </AnimatePresence>

                        </div>
                        <div className={'Question-Container'}>
                            <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(4)}}>
                                I would like to transfer from another branch of service to join the Ranger Regiment. How can I do that?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[4]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[4] && (
                                    <motion.p
                                        key={4}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                        You must be Active Duty Army to volunteer to serve the 75th Ranger Regiment. To switch you must complete your current contract with your branch of service or complete a DD368. After you are released, you must  contact a USAREC Recruiter to commit to Active Duty Army. Contact 75recruit@socom.mil for further information.
                                    </motion.p>
                                )}
                            </AnimatePresence>
                        </div>

                        <div className={'Question-Container'}>
                            <p className={'question-Paragraph'} onClick={()=> {handleIsClicked(5)}}>
                                What are the "Ranger Regiment pipelines" that I have heard some branches utilize?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[5]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[5] && (
                                    <motion.p
                                        key={5}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                        Several support/enabling officer branches (examples include MI, SC, LG, CM) manage programs often referred to as the Ranger Regiment Pipelines.
                                        Based on manpower projections and operational needs, representatives from each of these branches will identify talented officers at specific BOLC or CCC classes and conduct physical assessment and screening interviews.
                                        Specially selected officers will be given an opportunity to attend Ranger School and RASP 2 for potential assignment to the Regiment. Contact 75officerrecruit@socom.mil for further information.
                                    </motion.p>
                                )}
                            </AnimatePresence>
                        </div>

                        <div className={'Question-Container'}>
                            <p className={'question-Paragraph'} onClick={()=> {console.log("hello"); handleIsClicked(6)}}>
                                Where is the best place to find up-to-date information about the Ranger Regiment?
                                <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={isClicked[6]}/></div>
                            </p>
                            <AnimatePresence initial={false}>
                                {isClicked[6] && (
                                    <motion.p
                                        key={6}
                                        className={'Eligibility-Questions'}
                                        initial={{ height: 0 }}
                                        animate={{ height: "auto" }}
                                        exit={{ height: 0 }}
                                        transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                        Email us at 75recruit@socom.mil for enlisted soldiers and NCOs, or 75officerrecruit@socom.mil for officers and warrant officers.
                                    </motion.p>
                                )}
                            </AnimatePresence>
                        </div>
                    </motion.div>
                )
                break;

            default:
                divOptions = null;
        }
        return divOptions
    }

    const textOptions = ['Option 40 Ranger Contracts / Initial Entry Soldiers', 'General In Service Eligibility', 'Enlisted / NCO Questions', 'Officer / Warrant Officer Questions', 'Applications and Additional Opportunities']

    if (smallScreen) {
        return (
            <div className={'FAQ-Container'}>
                <div className={'FAQ-Header'} style={{ backgroundImage: `url(${faqPic})` }}>
                    <div style={{marginTop:'4rem'}}>FAQ'S</div>
                </div>
                <div className={'FAQ-Content'}>
                    <div className={'FAQ-Content-Options-Container'}>
                        <div className={'FAQ-Content-Options'}>
                            {textOptions.map((val, idx) =>
                                <>
                                    <div className={`FAQ-Content-Options-Main ${showDiv[idx] ? 'highlighted' : ''}`} onClick={() => {handleClick(idx)}}>{val}</div>
                                    <AnimatePresence initial={false} mode={'wait'}>{showDiv[idx] && options(idx)}</AnimatePresence>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className={'FAQ-RequestInfo'}>
                    <div className={'requestInfo-Button-Container'}>
                        <div style={{marginBottom:'1rem'}}>
                            Couldn't find the answer to your question?
                        </div>
                        <CustomButton onClick={() => setPageSelector(3)}>
                            Request Info
                        </CustomButton>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
                <div className={'FAQ-Container'}>
                    <div className={'FAQ-Header'} style={{ backgroundImage: `url(${faqPic})` }}>
                        <div style={{marginTop:'4rem'}}>FREQUENTLY ASKED QUESTIONS</div>
                    </div>
                    <div className={'FAQ-Content'}>
                        <div className={'FAQ-Content-Options-Container'}>
                            <div className={'FAQ-Content-Options'}>

                                <div className={`FAQ-Content-Options-Main ${showDiv[0] ? 'highlighted' : ''}`} onClick={() => {handleClick(0)}}>
                                    Option 40 Ranger Contracts / Initial Entry Soldiers
                                    <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={true} color={`${showDiv[0] ? 'white' : '#999999'}`}/></div>
                                </div>
                                <div className={`FAQ-Content-Options-Main ${showDiv[1] ? 'highlighted' : ''}`} onClick={() => { handleClick(1) }}>
                                    General In Service Eligibility
                                    <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={true} color={`${showDiv[1] ? 'white' : '#999999'}`}/></div>
                                </div>
                                <div className={`FAQ-Content-Options-Main ${showDiv[2] ? 'highlighted' : ''}`} onClick={() => { handleClick(2) }}>
                                    Enlisted / NCO Questions
                                    <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={true} color={`${showDiv[2] ? 'white' : '#999999'}`}/></div>
                                </div>
                                <div className={`FAQ-Content-Options-Main ${showDiv[3] ? 'highlighted' : ''}`} onClick={() => { handleClick(3) }}>
                                    Officer / Warrant Officer Questions
                                    <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={true} color={`${showDiv[3] ? 'white' : '#999999'}`}/></div>
                                </div>
                                <div className={`FAQ-Content-Options-Main ${showDiv[4] ? 'highlighted' : ''}`} onClick={() => { handleClick(4) }}>
                                    Applications and Additional Opportunities
                                    <div style={{width:'34px', height:'34px'}}><ExpandIcon isUp={true} color={`${showDiv[4] ? 'white' : '#999999'}`}/></div>
                                </div>

                            </div>
                            <AnimatePresence initial={false} mode={'wait'}>
                                {showDiv.map((val, index) => val && options(index))}
                            </AnimatePresence>
                        </div>
                    </div>
                    <div className={'FAQ-RequestInfo'}>
                        <div className={'requestInfo-Button-Container'}>
                            <div style={{marginBottom:'1rem'}}>
                                Couldn't find the answer to your question?
                            </div>
                            <CustomButton onClick={() => setPageSelector(3)}>
                                Request Info
                            </CustomButton>
                        </div>
                    </div>
                </div>
        )

    }


}

export default FAQ