import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown"
import '@leenguyen/react-flip-clock-countdown/dist/index.css'
import './AllTheCSS/CountDown.css'
import {useState} from "react";

const CountDown = () => {
    const [isComplete, setIsComplete] = useState(false);

    const countDownComplete = () => {
        setIsComplete(true)
    }
    return (
        <section className="flex flex-col justify-center items-center h-screen md:w-screen md:h-screen">
            <div className="bg-black/70 absolute w-full h-full"/>
            <div className="absolute flex flex-col items-center text-center">
                {isComplete ?
                    <span>Happening now!!</span>
                    :
                    <FlipClockCountdown
                        to={new Date(2024, 9, 0, 10, 0,0).getTime() + 24 * 3600 * 1000 + 5000}
                        labels={['DAYS', 'HOURS', 'MINUTES', 'SECONDS']}
                        duration={0.7}
                        onComplete={countDownComplete}
                        className={"flipClockCountdown"}
                    />
                }

            </div>
        </section>
    )
}

export default CountDown;