import  "./AllTheCSS/AccessModal.css"
import React from 'react'
import ModalHeader from './Pictures/AccessModalHeader.png'
import ModalHeaderLogo from './Pictures/ModalHeaderLogo.png'
import ModalHeaderExit from './Pictures/ModalHeaderExit.png'
import {useNavigate} from "react-router-dom";
interface AccessModalProps {
    informationType: string
    handleCloseModal: () => void

}
const AccessModal = ({informationType,handleCloseModal }:AccessModalProps) => {
    const handleLinkClick = () => {
        window.open('https://www.dhs.gov/real-id/real-id-faqs', '_blank')
    }


    return (
        <>
            <div className={'modal-container'}>
                <header className={'header-container'} style={{backgroundImage: `url(${ModalHeader})`}}>
                    <img className={'fifty-logo'} src={ModalHeaderLogo}/>
                    <img className={'exit-button'} onClick={() => handleCloseModal()} src = {ModalHeaderExit}/>
                </header>
                <main className={'main-container'}>
                    <div className={'content-body-container'}>
                        {informationType === 'No ID' &&
                            <>
                                <div className={'sub-header-container'}>
                                    <h2>JBLM ACCESS INFORMATION </h2>
                                </div>
                                <div className={'body-container'}>
                                    <p>Non-DoD ID card holders, ages 18 and older, attending the event  without a DoD ID card holder
                                        sponsor in the vehicle must be vetted  online prior to attending the event.
                                        Shortly after submitting the form,  you should receive a text message confirming or denying
                                        your access to  the event. Once registered you may proceed to either one of the two  Visitor Control
                                        Centers and utilize the Self-Service Kiosk to obtain  your pass for the event. The pass will only be valid
                                        for the duration of  the event </p>
                                    <div className={'anchor'}>
                                        <p style={{marginRight:'.3rem'}}>Visitor Pre-Registration Link:</p>  <a target={'_blank'} href={'https://pass.aie.army.mil/?b=usa&i=joint_base_lewis%E2%80%93mcchord&e=4784KRJN'}> https://pass.aie.army.mil/?b=usa&i=joint_base_lewis%E2%80%93mcchord&e=4784KRJN </a>
                                    </div>

                                    <p>Attending with your family? A separate registration per  individual is not necessary. You can add your other attendees to your  registration, by hitting “Add Attendee.” </p>
                                    <p>All adults 18 years and  older will have their government-issued ID scanned at the gate prior to  admission to the event. (Ages 17 and younger do not have to show ID.) </p>
                                    <div className={'anchor'}>
                                        <span>There  is a Real ID Act requirement for access to the event. A driver’s  license stating Federal Limits Apply or Not for Federal Use is not  acceptable by itself for
                                            identification.For more information regarding Real ID please visit <span onClick={() => handleLinkClick()} className={'span-link'}>https://www.dhs.gov/real-id/real-id-faqs </span> </span>

                                    </div>

                                    <p>Foreign  nationals will require a DoD ID card holder sponsor and must stop at  the Visitor’s Center before proceeding to the event.
                                        Questions about access to JBLM? Please email usarmy.jblm.imcom.list.des-aie@army.mil </p>
                                    <p>Joint Base Lewis-McChord is a U.S. Government federal installation. </p>
                                    <p>No  political activity (campaigning, handing out literature, etc.) is  allowed. Finally, no social protests or demonstrations are allowed on a  DoD military installation. </p>
                                </div>
                            </>}
                        {informationType === 'Acceptable Documents' &&
                            <>
                                <div className={'sub-header-container'}>
                                    <h2>JBLM ACCEPTABLE IDENTITY VERIFICATION DOCUMENTS FOR
                                        PRESENTATION WITH  A LICENSE OR ID STATING “FEDERAL LIMITS APPLY’ OR “NOT FOR FEDERAL USE” </h2>
                                </div>
                                <div className={'body-container'}>
                                    <ul>
                                        <li>DoD CAC. </li>
                                        <li>DoD Uniformed Services Identification and Privileges Cards.</li>
                                        <li>U.S. Passport or U.S. Passport Card. </li>
                                        <li>VA Issued Health Card </li>
                                        <li>Permanent resident card or Alien Registration Receipt Card (INS Form I-551). </li>
                                        <li>Foreign passport with a temporary I-551 stamp or temporary I-551 printed notation on a machine readable immigrant visa. </li>
                                        <li>Foreign passport with a current arrival-departure record or foreign  passport with INS Form I-94/I-94A bearing the same names as the passport  and containing an endorsement of the alien’s nonimmigrant status, if  that status authorizes the alien to work for an employer. </li>
                                        <li>Employment authorization document that contains a photograph (INS Form I-766). </li>
                                        <li>US Refugee Travel Document I-327 and I-571 issued by DHS and containing a photograph </li>
                                        <li>Employee Identification Card issued by a federal, state or local  government agency provided it contains a photograph and biometric  information such as: Name, DOB, Gender, Height, Eye Color and/or  Address. </li>
                                        <li>U.S. Coast Guard Merchant Mariner Card </li>
                                        <li>Transportation Worker ID Card (TWIC) issued by DHS </li>
                                        <li>Native American tribal document. </li>
                                        <li>U.S. Government issued, authenticated Federal PIV credentials. </li>
                                        <li>For personnel 17 and under, a school identification card with a photograph. </li>
                                        <li>US Military or Draft Record containing name and DOB. </li>
                                        <li>Washington State Enhanced Driver’s License or ID Card </li>
                                        <li>Washington State Enhanced Commercial Driver’s License (CDL)  </li>
                                    </ul>
                                </div>
                            </>}
                        {informationType === 'Prohibited Items' &&
                            <>
                                <div className={'sub-header-container'}>
                                    <h2>Prohibited ITEMS </h2>
                                </div>
                                <div className={'body-container'}>
                                    <p><strong>NO DRUGS:</strong> Marijuana and illegal drugs are strictly prohibited on Federal installations. </p>
                                    <p><strong>NO CONCEALED WEAPONS:</strong> JBLM does not recognize any concealed weapons permits. </p>
                                    <p><strong>NO  WEAPONS:</strong> Knives (of any sort or size), firearms (regardless of  credential or CWP), scissors, tasers, stun guns, pepper spray/mace,  pocket tools, box cutters, nunchakus, batons, toys that resemble guns,  laser pointers, fireworks, ammunition, etc.</p>
                                    <p><strong>NO DRONES, FLYERS OR  RADIO EQUIPMENT:</strong> Remote control aircraft, all flying/bouncing objects,  balloons, spray paint and silly string are not permitted. </p>
                                    <p><strong>NO SMOKING:</strong> Except in designated areas. No marijuana vape concentrate. </p>
                                </div>

                            </>}
                    </div>

                    <div className={'button-container'}>
                        <button className={'close-button'} onClick={() => handleCloseModal()}> CLOSE </button>
                    </div>

                </main>

            </div>

        </>
    )
}
export default AccessModal