import React from "react";
import './AllTheCSS/CustomButton.css'

interface props {
    children: React.ReactNode
    onClick: () => void
}

const CustomButton = ({onClick, children}: props) => {

    return (
        <div className={"CustomButton"} onClick={onClick} >
            {children}
        </div>
    )
}
export default CustomButton