import './AllTheCSS/Sponsors.css';
import PointeDuHoc from './Pictures/pointeDuHoc.webp'
import LeadTheWay from './Pictures/armyRangersLeadTheWayFund.webp'
import FirstResponder from './Pictures/firstResponderLiaisonNetwork.webp'
import Legends from './Pictures/legendsOfThe75th.webp'
import Reveal from "./Reveal";
const Sponsors = () => {

    const handleClick = (num:number) => {
        switch (num) {
            case 1:
                return window.location.href = 'https://www.leadthewayfund.org/'
            case 2:
                return window.location.href = 'https://www.frln.org/'
            case 3:
                return window.location.href = 'https://www.facebook.com/groups/2281972325275871//'
            case 4:
                return window.location.href = 'https://pointeduhocfoundation.com/'
        }

    };
    return (
        <>
            <div className={"Sponsors-Container"}>
                <Reveal>
                    <div className={"Sponsors-Wrapper"}>
                        <div className={"Sponsors-Message"}>
                            <h1>A very heartfelt thank you to our sponsors for helping to bring us all together.</h1>
                        </div>
                        <div className={"Sponsors-PicturesContainer"}>
                            <img className={"Sponsors-IconAR"} src={LeadTheWay} alt={'Pointe Du Hoc Foundation'} onClick={()=>handleClick(1)}/>
                            <img className={"Sponsors-IconFR"} src={FirstResponder} alt={'Pointe Du Hoc Foundation'} onClick={()=>handleClick(2)}/>
                            <img className={"Sponsors-IconL"} src={Legends} alt={'Pointe Du Hoc Foundation'} onClick={()=>handleClick(3)}/>
                            <img className={"Sponsors-IconPDH"} src={PointeDuHoc} alt={'Pointe Du Hoc Foundation'} onClick={()=>handleClick(4)}/>
                        </div>
                    </div>
                </Reveal>


            </div>
        </>
    );
}
export default Sponsors