import React, {useEffect, useRef, useState} from "react";
import DUI from "./Pictures/DUI";
import HamburgerIcon from "./Pictures/HamburgerIcon";
import {redirectToExternalSite} from "./Helpers";
import FaceBook from "./Pictures/FaceBook";
import Instagram from "./Pictures/Instagram";
import YouTube from "./Pictures/YoutTube";
import CloseIcon from "./Pictures/CloseIcon";
import ExpandIcon from "./Pictures/ExpandIcon";
import {AnimatePresence, motion} from "framer-motion";
import countdownTimer from "./CountdownTimer";
import CountdownTimer from "./CountdownTimer";

interface props {
    dropDown:number
    setDropDown: React.Dispatch<React.SetStateAction<number>>
    mobileDropDown: boolean
    setMobileDropDown:React.Dispatch<React.SetStateAction<boolean>>
    handleClick: (value: number, name: string) => void
}

const Header = ({dropDown, setDropDown, mobileDropDown, setMobileDropDown, handleClick}: props) => {
    const [isSmallScreen, setIsSmallScreen] = useState(false)
    const [isUp, setIsUp] = useState([false, false, false])
    const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0
    const headerRef = useRef<HTMLDivElement>(null)
    const dropDownRef = useRef<HTMLDivElement>(null)
    const justWork = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleMouseLeave = () => {
            setDropDown(0)
        }
        headerRef.current?.addEventListener('mouseleave', handleMouseLeave);
        return () => {
            headerRef.current?.removeEventListener('mouseleave', handleMouseLeave);
        }
    },[headerRef, setDropDown])

    useEffect(() => {
        setIsSmallScreen(window.innerWidth < 1200)
        const handleResize = () => {
            const newWindowWidth = window.innerWidth;
            if (newWindowWidth < 1200) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false)
            }
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    },[])

    useEffect(() => {
        document.body.style.overflowY = mobileDropDown ? 'hidden' : 'auto'
        const handleClickOff = (event: MouseEvent) => {
            if (dropDownRef.current && mobileDropDown && !dropDownRef.current.contains(event.target as Node)) {
                setMobileDropDown(false)
            }

        }
        document.addEventListener('click', handleClickOff);
        return () => {
            document.removeEventListener('click', handleClickOff);
        }
    },[mobileDropDown])

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;

            setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 100);

            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos, visible]);



    const handleDropDown = (num:number) => {
        switch (num) {
            case 1:
                return (
                    <div style={{color:'white', height:'10rem', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                        <div style={{height:'2rem', color:'#999999'}}>Who we are</div>
                        <div className={'dropDownText'} onClick={() => handleClick(1, '')} style={{height:'1.5rem', cursor:'pointer'}}>Mission</div>
                        <div className={'dropDownText'} onClick={() => handleClick(1, 'battalions')} style={{height:'1.5rem', cursor:'pointer'}}>The Battalions</div>
                        <div className={'dropDownText'} onClick={() => handleClick(1, 'LifeInReg')} style={{cursor:'pointer', width:'10rem'}}>Life in Regiment</div>
                    </div>
                )
            case 2:
                return (
                    <div style={{display:'flex', justifyContent:'space-between', width:'25%'}}>
                        <div style={{color:'white', height:'10rem', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                            <div style={{height:'2rem', color:'#999999', width:'10rem'}}>Becoming a Ranger</div>
                            <div className={'dropDownText'} onClick={() => handleClick(2, '')} style={{height:'1.5rem', cursor:'pointer'}}>Requirements</div>
                            <div className={'dropDownText'} onClick={() => handleClick(2, 'rasp')} style={{height:'1.5rem', cursor:'pointer'}}>RASP</div>
                        </div>
                        <div style={{color:'white', height:'10rem', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                            <div style={{height:'2rem', color:'#999999'}}>Ways to Serve</div>
                            <div className={'dropDownText'} onClick={() => handleClick(2, 'officer')} style={{height:'1.5rem', cursor:'pointer'}}>Officer</div>
                            <div className={'dropDownText'} onClick={() => handleClick(2, 'warrant')} style={{height:'1.5rem', cursor:'pointer', width:'10rem'}}>Warrant-Officer</div>
                            <div className={'dropDownText'} onClick={() => handleClick(2, 'enlisted')} style={{cursor:'pointer', width:'10rem'}}>Enlisted & NCO</div>
                        </div>
                    </div>
                )
            case 3:
                return (
                    <div style={{color:'white', height:'10rem', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                        <div style={{height:'2rem', color:'#999999'}}>Reach Out</div>
                        <div className={'dropDownText'} onClick={() => handleClick(3, 'recruiter')} style={{height:'1.5rem', cursor:'pointer', width:'15rem'}}>Contact a Recruiter</div>
                        <div className={'dropDownText'} onClick={() => handleClick(3, 'question')} style={{height:'1.5rem', cursor:'pointer'}}>Ask a Question</div>
                    </div>
                )
            default:
                return <></>
        }
    }

    const handleExpand = (idx: number, val: boolean) => {
        setIsUp(prevArray => {
            const newArray = [...prevArray]
            newArray[idx] = val
            return newArray
        })
    }

    const mobileDrop = () => {
        return (
            <div ref={dropDownRef} className={`MobileDropDown ${mobileDropDown ? 'active' : 'notActive'}`}>
                <div style={{display:'flex', justifyContent:"space-between", width:'90%'}}>
                    <div style={{ color:'white', height:'4rem', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <div style={{cursor:"pointer"}} onClick={() => {handleClick(0, '')}}>
                            {DUI()}
                        </div>
                    </div>
                    <div onClick={() => setMobileDropDown(false)} style={{cursor:"pointer", color:'white', height:'4rem', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        {CloseIcon()}
                    </div>
                </div>
                <div className={'MobileDropDown-Option-Container'}>
                    <div className={'MobileDropDown-Option'} style={{height:'4rem', cursor:'pointer'}} onClick={() => handleClick(0, '')}>HOME</div>
                    <div className={'MobileDropDown-Option'}onClick={() => handleExpand(0, !isUp[0]) }>
                        <div className={'MobileDropDown-Option-Name'}>ABOUT<ExpandIcon isUp={isUp[0]}/></div>
                        <AnimatePresence initial={false}>
                            {isUp[0] &&
                            <motion.ul
                                key={0}
                                className={'MobileDropDown-Ul'}
                                initial={{ height: 0 }}
                                animate={{ height: "auto" }}
                                exit={{ height: 0 }}
                                transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}>
                                Who We Are
                                <li onClick={() => handleClick(1, 'mission')} className={'MobileDropDown-Li'}>
                                    Mission
                                </li>
                                <li onClick={() => handleClick(1, 'battalions')} className={'MobileDropDown-Li'}>
                                    The Battalions
                                </li>
                                <li onClick={() => handleClick(1, 'LifeInReg')} className={'MobileDropDown-Li'}>
                                    Life in Regiment
                                </li>
                            </motion.ul>}
                        </AnimatePresence>

                    </div>
                    <div className={'MobileDropDown-Option'}onClick={() => handleExpand(1, !isUp[1]) }>
                        <div className={'MobileDropDown-Option-Name'}>CAREER PATH<ExpandIcon isUp={isUp[1]}/></div>
                        <AnimatePresence initial={false}>
                            {isUp[1] &&
                            <motion.div
                                key={1}
                                style={{overflow:'hidden'}}
                                initial={{ height: 0 }}
                                animate={{ height: "auto" }}
                                exit={{ height: 0 }}
                                transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}
                            >
                                <ul className={'MobileDropDown-Ul'}>
                                    Becoming a Ranger
                                    <li onClick={() => handleClick(2, '')} className={'MobileDropDown-Li'}>
                                        Requirements
                                    </li>
                                    <li onClick={() => handleClick(2, 'rasp')} className={'MobileDropDown-Li'}>
                                        RASP
                                    </li>

                                </ul>
                                <ul className={'MobileDropDown-Ul'}>
                                    Ways to Serve
                                    <li onClick={() => handleClick(2, 'officer')} className={'MobileDropDown-Li'}>
                                        Officer
                                    </li>
                                    <li onClick={() => handleClick(2, 'warrant')} className={'MobileDropDown-Li'}>
                                        Warrant-Officer
                                    </li>
                                    <li onClick={() => handleClick(2, 'enlisted')} className={'MobileDropDown-Li'}>
                                        Enlisted/NCO
                                    </li>
                                </ul>
                            </motion.div>
                            }
                        </AnimatePresence>
                    </div>
                    <div className={'MobileDropDown-Option'}onClick={() => handleExpand(2, !isUp[2]) }>
                        <div className={'MobileDropDown-Option-Name'}>CONNECT <ExpandIcon isUp={isUp[2]}/></div>
                        <AnimatePresence initial={false}>
                            {isUp[2] &&
                            <motion.ul
                                key={2}
                                className={'MobileDropDown-Ul'}
                                initial={{ height: 0 }}
                                animate={{ height: "auto" }}
                                exit={{ height: 0 }}
                                transition={{  duration: 0.15, ease: [0.03, 0.03, 0.03, 0.03] }}
                            >
                                Reach Out
                                <li onClick={() => handleClick(3, 'recruiter')} className={'MobileDropDown-Li'}>
                                    Contact a Recruiter
                                </li>
                                <li onClick={() => handleClick(3, 'question')} className={'MobileDropDown-Li'}>
                                    Ask a Question
                                </li>
                            </motion.ul>
                            }
                        </AnimatePresence>
                    </div>
                    <div className={'MobileDropDown-Option'} style={{height:'4rem', cursor:'pointer'}} onClick={() => handleClick(4, '')}>FAQ</div>
                    <div className={'MobileDropDown-Option'} style={{height:'4rem', cursor:'pointer'}} onClick={() => handleClick(5, '')}>EVENTS</div>
                    <div className={'DropDown-Links'}>
                        <div style={{cursor:'pointer', width:'auto', height:'42px'}} onClick={() => redirectToExternalSite("F")}>
                            {FaceBook('white', '42', '42')}
                        </div>
                        <div style={{cursor:'pointer', width:'auto', height:'42px'}} onClick={() => redirectToExternalSite("I")}>
                            {Instagram('white', '42', '42')}
                        </div>
                        <div style={{cursor:'pointer', width:'auto', height:'42px'}} onClick={() => redirectToExternalSite("Y")}>
                            {YouTube('white', '42', '42')}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <>
            {mobileDrop()}
            <div style={{width:'100%'}} ref={headerRef}>
                <div ref={headerRef} className={`App-Header-Container ${mobileDropDown ? 'notActive' : ''}`} style={{ top: visible ? '0' : '-100px', transition: 'top 0.3s' }}>
                    {isSmallScreen ?
                        <div className={'App-Header-Spacing'}>
                            <div style={{width:'10%', height:'4rem', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                <div style={{cursor:"pointer"}} onClick={() => {handleClick(0, '')}}>
                                    {DUI()}
                                </div>
                            </div>
                            <div onClick={(e) => {
                                e.stopPropagation()
                                setMobileDropDown(true)
                                setIsUp([false, false, false])
                            }} style={{cursor:'pointer', width:'10%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                {HamburgerIcon("white")}
                            </div>
                        </div>
                        :
                        <div className={'App-Header-Spacing'}>
                            <div style={{width:'10%'}}>
                                <div style={{cursor:"pointer"}} onClick={() => {handleClick(0, '')}}>
                                    {DUI()}
                                </div>
                            </div>
                            <div className={'App-Header'}>
                                <span className={`headerName ${dropDown === 21 ? 'active' : ''}`}
                                      onMouseEnter={() => setDropDown(21)} onClick={() =>handleClick(0, '')}>Home</span>
                                <span className={`headerName ${dropDown === 1 ? 'active' : ''}`}
                                      onMouseEnter={() => setDropDown(1)}
                                      onClick={() => isTouchScreen ? dropDown === 1 ? handleClick(1, '') : setDropDown(1) : handleClick(1, '')}>About</span>
                                <span className={`headerName ${dropDown === 2 ? 'active' : ''}`}
                                      onMouseEnter={() => setDropDown(2)}
                                      onClick={() => isTouchScreen ? dropDown === 2 ? handleClick(2, '') : setDropDown(2) : handleClick(2, '')}>Career Path</span>
                                <span className={`headerName ${dropDown === 3 ? 'active' : ''}`}
                                      onMouseEnter={() => setDropDown(3)}
                                      onClick={() => isTouchScreen ? dropDown === 3 ? handleClick(3, '') : setDropDown(3) : handleClick(3, '')}>Connect</span>
                                <span className={`headerName ${dropDown === 22 ? 'active' : ''}`}
                                      onMouseEnter={() => setDropDown(22)} onClick={() => handleClick(4, '')}>FAQ</span>
                                <span className={`headerName ${dropDown === 23 ? 'active' : ''}`}
                                      onMouseEnter={() => setDropDown(23)} onClick={() => handleClick(5, '')}>Events</span>
                            </div>
                            <div className={'ExternalLinks'}>
                                <div style={{cursor:'pointer'}} onClick={() => redirectToExternalSite("F")}>
                                    {FaceBook('white','','')}
                                </div>
                                <div style={{cursor:'pointer'}} onClick={() => redirectToExternalSite("I")}>
                                    {Instagram('white', '', '')}
                                </div>
                                <div style={{cursor:'pointer'}} onClick={() => redirectToExternalSite("Y")}>
                                    {YouTube('white', '', '')}
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div ref={justWork} className={`dropDown-Text-Container ${dropDown <= 3 && dropDown != 0 ? 'active' : ''}`}>
                    {handleDropDown(dropDown)}
                </div>
            </div>
        </>
    )
}
export default Header